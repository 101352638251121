import Vue from 'vue'
import Router from 'vue-router'
import LandingPage from '../components/LandingPage.vue'
import Projects from '../components/Projects.vue'
import About from '../components/About.vue'
//import firebase from 'firebase'
Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '/',
      name: 'LandingPage',
      component: LandingPage,
      meta: { requiresAuth: false }
    },
    {
        path: '/projects',
        name: 'Projects',
        component: Projects,
        meta: { requiresAuth: false }
      },
      {
        path: '/about',
        name: 'About',
        component: About,
        meta: { requiresAuth: false }
      },
  ]
});


// Secure routes
router.beforeEach((to, from, next) => {
  //let currentUser = firebase.auth().currentUser;
  let requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  if (requiresAuth ) {
    next('landing')
  }
  else next();
})

export default router;
