<template>
  <div id="app">
    <!-- <div class="content-wrapper"> -->
      <router-view class="margin-nav content-wrapper" style="height: 100%;"></router-view>
    <!-- </div> -->
  </div>
</template>

<script>

export default {
  name: "App",
  components: {
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}
body {
  margin: 0;
  /* overflow-y: hidden; */
}

.content-wrapper {
  padding-top: 100px;
  /* min-height: calc(100vh - 100px); */
  min-height: calc(100vh - 200px);
  padding-bottom: 100px;
  /* min-height: 100vh; */
}

.hello {
  display: flex;
  align-items: center;
}
</style>
