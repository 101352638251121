<template>
  <div class="">

  <div>
   <h3> Curtis Staples</h3>
    <p> curtis@curtisstaples.com </p>
    <a href="https://www.linkedin.com/in/curtisstaples/" target="_blank">Linkedin</a>
    <p> I'm currently revamping my site. To see projects I've built in the past, feel free to browse the pdf below. </p>
  </div>
  
  
  <div>
    <pdf
			v-for="i in numPages"
			:key="i"
			:src="src"
			:page="i"
		></pdf>
  </div>

    
  </div>
</template>

<script>

import pdf from 'vue-pdf'

var loadingTask = pdf.createLoadingTask('/Curtis_Staples_Portfolio.pdf');

export default {
	components: {
		pdf
	},
	data() {
		return {
			src: loadingTask,
			numPages: undefined,
		}
	},
	mounted() {

		this.src.promise.then(pdf => {

			this.numPages = pdf.numPages;
		});
	}
}

</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .hello {
    height: 100%;
    text-align: center;
  }

  img {
    max-width: 1080px;
    margin-left: auto;
    margin-right: auto;
  }
</style>
