<template>
  <div class="hello">
    <div class="container-thesis">
      <h3 class="mb-40">
        Buying a Batman comic in 1938 was buying a piece of the culture from
        that time period. The same can be said for collecting stamps in the 50s.
        Or buying a Jordan rookie card in the 80s.
      </h3>
      <h3 class="mb-40">
        Our culture is now becoming increasingly digital. And it's important
        that we are able to buy into it. That's where we come in. We making
        buying into digital culture easy.
      </h3>
      <h3>
        Are you shifting culture? If so, we'd love to talk. Send a quick note to
        curtis@washerecollections.com to get started.
      </h3>
    </div>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-family: "Source Code Pro", monospace;
  color: black;
}

h3 {
  /* margin: 40px 0 0; */
  margin: 0;
  font-family: "Source Code Pro", monospace;
  color: black;
}

.mb-40 {
  margin-bottom: 40px;
}

.h3-margin {
  margin: 40px 0 0;
}

h4 {
  font-family: "Source Code Pro", monospace;
  color: black;
}

ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.container-thesis {
  padding: 16px;
  max-width: 960px;
  margin-left: auto;
  margin-right: auto;
}
</style>
